import {
  type ApiServiceModelDescriptor,
  type CloudFlowNodeType,
  type Member,
  type MemberReference,
  ModelType,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-admin";

import { calculateConditionalNodeOutputModel } from "./calculated-node-output-models/conditional-node";
import { calculateDateTransformationNodeOutputModel } from "./calculated-node-output-models/date-transform-node";
import { calculateTransformationNodeOutputModel } from "./calculated-node-output-models/transformation-node";
import {
  isActionNode,
  isConditionNode,
  isDateTransformationNode,
  isFilterNode,
  isTransformationNode,
  isTriggerNode,
} from "./pattern-matchers";
import { type NodeModelWithId } from "./types";

export class ReferencedNodeNotFoundException extends Error {
  public readonly name = "ReferencedNodeNotFoundException";

  constructor(nodeId: string) {
    super(`Could not find referenced node id: ${nodeId}`);
  }
}

export type ModelPath = string[];

export type GetOutputModelForActionNodeFn = (
  referencedNode: NodeModelWithId<CloudFlowNodeType.ACTION>
) => Promise<UnwrappedApiServiceModelDescriptor | null>;

export async function getNodeOutputModel(
  getOutputModelForActionNode: GetOutputModelForActionNodeFn,
  nodes: NodeModelWithId[],
  nodeId: string
): Promise<UnwrappedApiServiceModelDescriptor | null> {
  const referencedNode = nodes.find(({ id }) => nodeId === id);
  if (referencedNode === undefined) {
    throw new ReferencedNodeNotFoundException(nodeId);
  }

  switch (true) {
    case isTriggerNode(referencedNode):
      return wrapModelWithListModel({
        type: ModelType.STRUCTURE,
        members: { startTime: { model: { type: ModelType.TIMESTAMP, timestampFormat: "x" } } },
      });
    case isActionNode(referencedNode):
      return getOutputModelForActionNode(referencedNode);
    case isConditionNode(referencedNode):
    case isFilterNode(referencedNode): {
      return calculateConditionalNodeOutputModel(referencedNode, nodes, getOutputModelForActionNode);
    }
    case isTransformationNode(referencedNode): {
      return calculateTransformationNodeOutputModel(referencedNode, nodes, getOutputModelForActionNode);
    }
    case isDateTransformationNode(referencedNode): {
      return calculateDateTransformationNodeOutputModel(referencedNode, nodes, getOutputModelForActionNode);
    }
  }

  throw new Error(`Unable to provide an output model for node ${referencedNode.id} with type ${referencedNode.type}`);
}

export function getModelRequiredTupleByPath(
  model: UnwrappedApiServiceModelDescriptor,
  path: ModelPath,
  required = false
): [UnwrappedApiServiceModelDescriptor, boolean] {
  if (path.length === 0) {
    return [model, required];
  }

  switch (model.type) {
    case ModelType.LIST:
      return getModelRequiredTupleByPath(model.member.model, path);
    case ModelType.STRUCTURE: {
      const [memberName, ...pathRest] = path;
      const memberModel = model.members[memberName];
      if (memberModel === undefined) {
        throw new Error(`Could not get model for path token ${memberName}`);
      }

      return getModelRequiredTupleByPath(memberModel.model, pathRest);
    }
    case ModelType.MAP: {
      const [memberName, ...pathRest] = path;
      let member: Member | undefined;
      if (model.keyMemberName === memberName) {
        member = model.keyMember;
      }
      if (model.valueMemberName === memberName) {
        member = model.valueMember;
      }
      if (member === undefined) {
        throw new Error(`Could not get model for path token ${memberName}`);
      }

      return getModelRequiredTupleByPath(member.model, pathRest);
    }
    default:
      throw new Error("Model path out of bounds!");
  }
}

export function getModelByPath(model: UnwrappedApiServiceModelDescriptor, path: ModelPath) {
  const [outputModel] = getModelRequiredTupleByPath(model, path);
  return outputModel;
}

export function wrapModelWithListModel(model: UnwrappedApiServiceModelDescriptor): UnwrappedApiServiceModelDescriptor {
  if (model.type === ModelType.LIST) {
    return model;
  }
  return {
    type: ModelType.LIST,
    member: {
      model,
    },
  };
}

export function isUnwrappedMember(member: Member | MemberReference): member is Member {
  return Object.hasOwn(member, "model");
}

const MAX_DEPTH_OF_RECURSION = 10;

async function doModelUnwrap(
  getReferencedModelById: (modelId: string) => Promise<WithFirebaseModel<ApiServiceModelDescriptor>>,
  modelToUnwrap: ApiServiceModelDescriptor,
  trail: string[]
): Promise<WithFirebaseModel<UnwrappedApiServiceModelDescriptor>> {
  if (hasCircuitsWithLength(trail, MAX_DEPTH_OF_RECURSION)) {
    return {
      type: ModelType.STRING,
      enum: ["MAX_DEPTH_OF_RECURSION_REACHED"],
      enumDescriptions: [`We support up to ${MAX_DEPTH_OF_RECURSION} levels of recursion`],
    };
  }

  switch (modelToUnwrap.type) {
    case ModelType.LIST: {
      const [memberModel, newTrail] = await unwrapMemberWithTrail(getReferencedModelById, modelToUnwrap.member, trail);
      return {
        ...modelToUnwrap,
        member: {
          documentation: modelToUnwrap.member.documentation,
          model: await doModelUnwrap(getReferencedModelById, memberModel, newTrail),
        },
      };
    }
    case ModelType.STRUCTURE: {
      const membersEntries = await Promise.all(
        Object.entries(modelToUnwrap.members).map(async ([memberName, member]) => {
          const [memberModel, newTrail] = await unwrapMemberWithTrail(getReferencedModelById, member, trail);
          return [
            memberName,
            {
              model: await doModelUnwrap(getReferencedModelById, memberModel, newTrail),
              documentation: member.documentation,
            },
          ] as const;
        })
      );
      return {
        ...modelToUnwrap,
        members: Object.fromEntries(membersEntries),
      };
    }
    case ModelType.MAP: {
      const [keyMemberModel, newKeyTrail] = await unwrapMemberWithTrail(
        getReferencedModelById,
        modelToUnwrap.keyMember,
        trail
      );
      const [valueMemberModel, newValueTrail] = await unwrapMemberWithTrail(
        getReferencedModelById,
        modelToUnwrap.valueMember,
        trail
      );

      return {
        ...modelToUnwrap,
        keyMember: {
          model: await doModelUnwrap(getReferencedModelById, keyMemberModel, newKeyTrail),
          documentation: modelToUnwrap.keyMember.documentation,
        },
        valueMember: {
          model: await doModelUnwrap(getReferencedModelById, valueMemberModel, newValueTrail),
          documentation: modelToUnwrap.valueMember.documentation,
        },
      };
    }
    default:
      return modelToUnwrap as WithFirebaseModel<UnwrappedApiServiceModelDescriptor>;
  }
}

async function unwrapMemberWithTrail(
  getReferencedModelById: (modelId: string) => Promise<WithFirebaseModel<ApiServiceModelDescriptor>>,
  member: Member | MemberReference,
  trail: string[]
): Promise<[ApiServiceModelDescriptor, string[]]> {
  if (isUnwrappedMember(member)) {
    return [member.model, trail];
  }
  return [await getReferencedModelById(member.modelId), [...trail, member.modelId]];
}

function hasCircuitsWithLength(trail: string[], length: number) {
  let tortoise = 0;
  let hare = 1;
  let hasCircuit = false;

  while (hare < trail.length && !hasCircuit) {
    hasCircuit = trail[tortoise] === trail[hare] && hare - tortoise >= length;
    tortoise += 1;
    hare += 2;
  }

  return hasCircuit;
}

export const unwrapModel = async (
  getReferencedModelById: (modelId: string) => Promise<WithFirebaseModel<ApiServiceModelDescriptor>>,
  modelToUnwrap: ApiServiceModelDescriptor
): Promise<WithFirebaseModel<UnwrappedApiServiceModelDescriptor>> =>
  doModelUnwrap(getReferencedModelById, modelToUnwrap, []);

export function isPathRelative(basePath: ModelPath, path: ModelPath) {
  return basePath.every((token, idx) => path[idx] === token);
}

export function getRelativePath(basePath: ModelPath, path: ModelPath) {
  if (!isPathRelative(basePath, path)) {
    throw new Error(`Cannot extract relative path from [${path.join(",")}] with base [${basePath.join(",")}]`);
  }
  return path.slice(basePath.length);
}

export function getCommonPath(basePath: ModelPath, path: ModelPath) {
  const index = basePath.findIndex((token, idx) => path[idx] !== token);
  return basePath.slice(0, index);
}
