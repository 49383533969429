import { useId } from "react";

import { DATE_TIME_DURATIONS } from "@doitintl/cmp-models";
import {
  FormControl,
  FormHelperText,
  Grid2 as Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useFormikContext } from "formik";

import { useFieldCommonProps } from "../../../ApiActionParametersForm/useFieldCommonProps";
import { FieldSectionHeader } from "../../../Common/FieldSectionHeader";
import { type DateTimeSchema } from "./schema";

export const ModifyDateForm = ({ modifier }: { modifier: "add" | "subtract" }) => {
  const { getFieldProps } = useFormikContext<DateTimeSchema>();
  const valueCommonProps = useFieldCommonProps(getFieldProps("value"), "Value", true);

  const durationLabelId = useId();
  const durationCommonProps = useFieldCommonProps(getFieldProps("duration"), "Duration", true);

  const newFieldNameCommonProps = useFieldCommonProps<string>(getFieldProps("newFieldName"), "New field name", true);

  return (
    <Stack
      spacing={2}
      sx={{
        pb: 4,
      }}
    >
      <TextField fullWidth variant="outlined" size="small" {...newFieldNameCommonProps} />
      <FieldSectionHeader
        title={`How to use ${modifier}`}
        subtitle={`Define the amount of time you want to ${modifier} from your chosen time field`}
      />
      <Grid container spacing={1}>
        <Grid size={3}>
          <TextField fullWidth variant="outlined" size="small" type="number" {...valueCommonProps} />
        </Grid>
        <Grid size={9}>
          <FormControl fullWidth required error={durationCommonProps.error}>
            <InputLabel size="small" id={durationLabelId}>
              {durationCommonProps.label}
            </InputLabel>
            <Select
              labelId={durationLabelId}
              size="small"
              {...durationCommonProps}
              sx={{ textTransform: "capitalize" }}
            >
              {DATE_TIME_DURATIONS.map((type) => (
                <MenuItem key={type} value={type} sx={{ textTransform: "capitalize" }}>
                  {type}
                </MenuItem>
              ))}
            </Select>
            {durationCommonProps.error && <FormHelperText>{durationCommonProps.helperText}</FormHelperText>}
          </FormControl>
        </Grid>
      </Grid>
    </Stack>
  );
};
