import { CloudFlowNodeType, type ReferencedNodeValue } from "@doitintl/cmp-models";

import { type NodeModelWithId } from "./types";

export function isReferencedNodeValue(value: unknown): value is ReferencedNodeValue {
  if (value === null || typeof value !== "object") {
    return false;
  }
  const keys = Object.keys(value);
  const expectedKeys = ["referencedNodeId", "referencedField"];

  return keys.length === expectedKeys.length && expectedKeys.every((expectedKey) => expectedKey in value);
}

export function areReferencedNodeValuesEqual(
  a: ReferencedNodeValue | null | undefined,
  b: ReferencedNodeValue | null | undefined
): boolean {
  return (
    isReferencedNodeValue(a) &&
    isReferencedNodeValue(b) &&
    a.referencedNodeId === b.referencedNodeId &&
    a.referencedField.length === b.referencedField.length &&
    a.referencedField.every((token, idx) => token === b.referencedField[idx])
  );
}

export function isTriggerNode(
  nodeEntity: NodeModelWithId
): nodeEntity is NodeModelWithId<CloudFlowNodeType.TRIGGER | CloudFlowNodeType.MANUAL_TRIGGER> {
  return nodeEntity.type === CloudFlowNodeType.MANUAL_TRIGGER || nodeEntity.type === CloudFlowNodeType.TRIGGER;
}

export function isActionNode(nodeEntity: NodeModelWithId): nodeEntity is NodeModelWithId<CloudFlowNodeType.ACTION> {
  return nodeEntity.type === CloudFlowNodeType.ACTION;
}

export function isFilterNode(nodeEntity: NodeModelWithId): nodeEntity is NodeModelWithId<CloudFlowNodeType.FILTER> {
  return nodeEntity.type === CloudFlowNodeType.FILTER;
}

export function isConditionNode(
  nodeEntity: NodeModelWithId
): nodeEntity is NodeModelWithId<CloudFlowNodeType.CONDITION> {
  return nodeEntity.type === CloudFlowNodeType.CONDITION;
}

export function isTransformationNode(
  nodeEntity: NodeModelWithId
): nodeEntity is NodeModelWithId<CloudFlowNodeType.TRANSFORMATION> {
  return nodeEntity.type === CloudFlowNodeType.TRANSFORMATION;
}

export function isDateTransformationNode(
  nodeEntity: NodeModelWithId
): nodeEntity is NodeModelWithId<CloudFlowNodeType.DATETIME_TRANSFORM> {
  return nodeEntity.type === CloudFlowNodeType.DATETIME_TRANSFORM;
}
