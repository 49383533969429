import {
  type CloudFlowNodeType,
  type DateTimeTransformations,
  ModelType,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";

import {
  getModelByPath,
  getNodeOutputModel,
  type GetOutputModelForActionNodeFn,
  wrapModelWithListModel,
} from "../model-descriptors";
import { type NodeModelWithId } from "../types";

export async function calculateDateTransformationNodeOutputModel(
  transformationNode: NodeModelWithId<CloudFlowNodeType.DATETIME_TRANSFORM>,
  nodes: NodeModelWithId[],
  getOutputModelForActionNode: GetOutputModelForActionNodeFn
) {
  const referencedNode = await getNodeOutputModel(
    getOutputModelForActionNode,
    nodes,
    transformationNode.parameters.referencedNodeId
  );
  if (referencedNode === null) {
    return null;
  }

  const transform = transformationNode.parameters.transformation;

  const exactModel = getModelByPath(referencedNode, transformationNode.parameters.referencedField.slice(0, -1));

  extendModel(exactModel, transform);

  return wrapModelWithListModel(referencedNode);
}

function extendModel(outputModel: UnwrappedApiServiceModelDescriptor, transformation: DateTimeTransformations) {
  let extendedModel = outputModel;
  if (outputModel.type === ModelType.LIST) {
    extendedModel = outputModel.member.model;
  }

  if (extendedModel.type !== ModelType.STRUCTURE) {
    throw new Error(`${transformation.type} transformation node can only reference structures or list of structures`);
  }

  switch (transformation.type) {
    case "add":
    case "subtract":
      extendedModel.members[transformation.newFieldName] = {
        model: {
          type: ModelType.TIMESTAMP,
          timestampFormat: "x",
        },
      };
      break;
    case "format":
      extendedModel.members[transformation.newFieldName] = {
        model: {
          type: ModelType.STRING,
        },
      };
      break;
  }

  return outputModel;
}
