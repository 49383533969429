import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router";
import { CloudFlowNodeType, type CloudFlowProvider } from "@doitintl/cmp-models";
import {
  addEdge,
  type Connection,
  type Edge,
  type EdgeMouseHandler,
  getOutgoers,
  type Node,
  type OnConnect,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "@xyflow/react";
import { v4 as uuidv4 } from "uuid";

import { consoleErrorWithSentry } from "../../../../utils";
import { CHOICE_OPTIONS } from "../../Dialog/ManageIfNodeDialog";
import {
  type BaseCloudflowHit,
  type CHANGE_TRIGGER_OPTIONS,
  type CloudFlowNode,
  type NodeEdgeManagerConfig,
  NodeOperationType,
  type RFNode,
  type UpdateCloudflowNodes,
} from "../../types";
import utils from "../utils/conditionNodeUtils";
import { applyGraphLayout } from "../utils/layoutUtils";
import {
  mapCloudFlowNodes,
  mapLeafNodesWithGhosts,
  mapTransitionsToEdges,
  mergeCloudflowNodes,
  sortEdgesByHandle,
} from "../utils/nodeTransformUtils";
import {
  createTransitionPayload,
  findNodeById,
  getCreateActionNodePayload,
  getIncomerNode,
  getTreeOfOutgoers,
  getTriggerNodePayload,
  getUpdateActionNodePayload,
  initializeNode,
  isGhostNode,
} from "../utils/nodeUtils";
import { getTriggerByOption } from "../utils/triggerUtils";
import { useModalManager } from "./CloudflowModalsProvider";
import { useCloudflowOperations } from "./CloudflowOperationsProvider";
import { useCloudFlowContext } from "./CloudFlowProvider";
import { useReferencedNodeLookup } from "./hooks/useReferencedNodeLookup";
import { extractTransitions } from "./utils";

const NodeEdgeManagerContext = createContext<NodeEdgeManagerConfig>({} as NodeEdgeManagerConfig);

export const useNodeEdgeManager = () => {
  const context = useContext(NodeEdgeManagerContext);
  if (!context) {
    throw new Error("useNodeEdgeManager must be used within a NodeEdgeManagerProvider");
  }
  return context;
};

type Props = {
  children: ReactNode;
};

export const NodeEdgeManagerProvider = ({ children }: Props) => {
  const { flowId, customerId } = useParams<{ customerId: string; flowId: string }>();
  const [nodes, setNodes, onNodesChange] = useNodesState<Node<RFNode>>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const { createOrUpdate, remove, updateNodes, publishCloudflow, replaceNodes, attachBlueprint } =
    useCloudflowOperations();
  const { cloudFlow, cloudFlowNodes, upsertCloudFlowNodes, deleteCloudFlowNode, addCloudFlowNode } =
    useCloudFlowContext();

  const { openModal, closeAllModals, focusedNodeId, closeModal, isModalVisible } = useModalManager();

  const activeNode: Node<RFNode> | undefined = nodes.find((node) => node.selected) as Node<RFNode>;
  const { getEdges, getNodes } = useReactFlow<Node<RFNode>>();
  const [operationType, setOperationType] = useState<NodeOperationType>(NodeOperationType.CREATE);
  const [manageIfActionsId, setManageIfActionsId] = useState<string>("");
  const [deleteIfNodeId, setDeleteIfNodeId] = useState<string>("");
  const [deletionCandidateNodeId, setDeletionCandidateNodeId] = useState<string>("");
  const [interactionEnabled, setInteractionEnabled] = useState<boolean>(true);
  const prevNodeSignature = useRef<string | null>(null);
  const { nodesWithReferencedNode, findNodesWithReferencedNode, cleanupNodesWithReferencedNode } =
    useReferencedNodeLookup();
  const [selectedProvider, setSelectedProvider] = useState<CloudFlowProvider>();

  const selectNode = useCallback(
    (nodeId: string | null) => {
      setNodes((prev) =>
        prev.map((node) => ({
          ...node,
          selected: nodeId === node.id,
        }))
      );
    },
    [setNodes]
  );

  const applyLayoutAndSetState = useCallback(
    (nodes: Node<RFNode<CloudFlowNodeType>>[], edges: Edge[]) => {
      const { positionedNodes, positionedEdges } = applyGraphLayout(nodes, edges);
      setNodes(positionedNodes);
      setEdges(positionedEdges);
    },
    [setEdges, setNodes]
  );

  const handleEditNode = useCallback(
    (node: Node<RFNode<CloudFlowNodeType>>) => {
      selectNode(node.id);
    },
    [selectNode]
  );

  const handleDeleteNode = useCallback(
    async (nodeId: string) => {
      selectNode(null);
      const currentNodes = getNodes();
      const nodeToDelete = findNodeById(currentNodes, nodeId);
      if (!nodeToDelete) return;

      if (nodeToDelete.type === CloudFlowNodeType.CONDITION) {
        setDeleteIfNodeId(nodeId);
        return;
      }

      const nodesWithReferencedNode = findNodesWithReferencedNode(nodeId);

      if (nodesWithReferencedNode.length > 0) {
        setDeletionCandidateNodeId(nodeId);
        openModal("removeReferencedNode");
        return;
      }

      try {
        const nodesResponse = await remove(customerId, flowId, nodeId);
        if (nodesResponse) {
          deleteCloudFlowNode({
            deletedNodes: nodesResponse.deletedNodes,
            updatedNodes: extractTransitions(nodesResponse.updatedNodes),
          });
        }
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [selectNode, getNodes, findNodesWithReferencedNode, openModal, remove, customerId, flowId, deleteCloudFlowNode]
  );

  const handleDeleteReferencedNode = useCallback(async () => {
    try {
      const nodesResponse = await remove(customerId, flowId, deletionCandidateNodeId);
      if (nodesResponse) {
        deleteCloudFlowNode({
          deletedNodes: nodesResponse.deletedNodes,
          updatedNodes: extractTransitions(nodesResponse.updatedNodes),
        });
        cleanupNodesWithReferencedNode();
      }
    } catch (error) {
      consoleErrorWithSentry(error);
    } finally {
      setDeletionCandidateNodeId("");
      closeModal("removeReferencedNode");
    }
  }, [
    remove,
    customerId,
    flowId,
    deletionCandidateNodeId,
    deleteCloudFlowNode,
    cleanupNodesWithReferencedNode,
    closeModal,
  ]);

  const getLabelForTargetNode = useCallback(
    (targetNodeId: string): string | undefined => {
      const targetNodeEdge = getEdges().find((edge) => edge.target === targetNodeId);
      return targetNodeEdge?.data?.label as string;
    },
    [getEdges]
  );

  const addNewCloudflowNode = useCallback(
    async (nodeType: CloudFlowNodeType, nodeId: string, targetNodeId: string) => {
      const targetedNode = findNodeById(getNodes(), targetNodeId);
      if (!targetedNode) {
        return;
      }
      const incomer = getIncomerNode(targetedNode, getNodes(), getEdges());
      const targetNodeLabel =
        incomer.type === CloudFlowNodeType.CONDITION ? getLabelForTargetNode(targetNodeId) : undefined;
      const newNode = initializeNode(nodeType, nodeId);
      const newNodeRequestData = createTransitionPayload(incomer.id, targetNodeLabel, newNode, targetedNode);

      try {
        const nodesResponse = await createOrUpdate(customerId, flowId, newNodeRequestData);
        if (nodesResponse) {
          addCloudFlowNode({
            addedNode: nodesResponse?.addedNode,
            updatedNodes: extractTransitions(nodesResponse.updatedNodes),
          });
        }
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [getNodes, getEdges, getLabelForTargetNode, createOrUpdate, customerId, flowId, addCloudFlowNode]
  );

  const addNewTriggerNode = useCallback(
    async (nodeType: CloudFlowNodeType, nodeId: string) => {
      const triggerNode = findNodeById(getNodes(), nodeId);
      if (!triggerNode) {
        return;
      }
      const { triggerNodeWithTransitions, updateNodesPayload } = getTriggerNodePayload(triggerNode, nodeType, flowId);
      const updatedNodes = getNodes().map((node: Node<RFNode>) =>
        node.id === nodeId
          ? {
              ...triggerNodeWithTransitions,
              data: {
                ...triggerNodeWithTransitions.data,
                onEditNode: () => {
                  handleEditNode(triggerNodeWithTransitions);
                },
                onDeleteNode: () => handleDeleteNode(nodeId),
              },
              selected: true,
            }
          : node
      );
      applyLayoutAndSetState(updatedNodes, getEdges());
      try {
        const nodesResponse = await updateNodes(customerId, updateNodesPayload);
        if (nodesResponse) {
          upsertCloudFlowNodes(nodesResponse);
        }
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [
      applyLayoutAndSetState,
      upsertCloudFlowNodes,
      customerId,
      flowId,
      getEdges,
      getNodes,
      handleDeleteNode,
      handleEditNode,
      updateNodes,
    ]
  );

  // Do we need this method?
  const handleAddNode = useCallback(
    async (nodeType: CloudFlowNodeType, nodeId: string, provider?: CloudFlowProvider) => {
      if (nodeType === CloudFlowNodeType.TRIGGER || nodeType === CloudFlowNodeType.MANUAL_TRIGGER) {
        await addNewTriggerNode(nodeType, nodeId);
        return;
      }

      if (nodeType === CloudFlowNodeType.ACTION) {
        setOperationType(NodeOperationType.CREATE);
        setSelectedProvider(provider);
        openModal("action", nodeId);
        return;
      }

      const currentTargetNode = findNodeById(getNodes(), nodeId);
      if (!currentTargetNode) {
        return;
      }

      if (nodeType === CloudFlowNodeType.CONDITION && !isGhostNode(currentTargetNode)) {
        setManageIfActionsId(nodeId);
        return;
      }

      const newNodeId = uuidv4();
      await addNewCloudflowNode(nodeType, newNodeId, nodeId);
    },
    [addNewCloudflowNode, addNewTriggerNode, getNodes, openModal, setOperationType, setSelectedProvider]
  );

  const handleAttachBlueprint = useCallback(
    async (nodeId: string) => {
      openModal("blueprint", nodeId);
    },
    [openModal]
  );

  const handleAddBlueprint = useCallback(
    async (nodeId: string, blueprintId: string) => {
      const currentTargetNode = findNodeById(getNodes(), nodeId);

      if (!currentTargetNode) {
        return;
      }
      const parentNode = getIncomerNode(currentTargetNode, getNodes(), getEdges());

      const data = await attachBlueprint({
        blueprintId,
        targetNodeId: parentNode.id,
        transition: parentNode.data.nodeData.transitions?.find((transition) => transition.targetNodeId === nodeId),
      });

      if (!data) {
        return;
      }
      upsertCloudFlowNodes(data);
      closeModal("blueprint");
    },
    [getNodes, getEdges, attachBlueprint, upsertCloudFlowNodes, closeModal]
  );

  const onConfirmDeleteIfNode = useCallback(async () => {
    selectNode(null);
    const nodeToDelete = findNodeById(getNodes(), deleteIfNodeId);
    if (!nodeToDelete) return;
    try {
      const nodesResponse = await remove(customerId, flowId, nodeToDelete.id);
      if (nodesResponse) {
        deleteCloudFlowNode({
          deletedNodes: nodesResponse.deletedNodes,
          updatedNodes: extractTransitions(nodesResponse.updatedNodes),
        });
      }
    } catch (error) {
      consoleErrorWithSentry(error);
    }
    setDeleteIfNodeId("");
  }, [selectNode, getNodes, deleteIfNodeId, remove, customerId, flowId, deleteCloudFlowNode]);

  const handleEdgeClick = useCallback(
    async (edgeData: Edge) => {
      setEdges((prevEdges: Edge[]) =>
        prevEdges.map((edge) => {
          if (edge.id === edgeData.id) {
            return {
              ...edge,
              data: {
                ...edge.data,
                handleAddNode,
                setInteractionEnabled,
                handleAttachBlueprint,
              },
            };
          }
          return edge;
        })
      );
    },
    [setEdges, handleAddNode, handleAttachBlueprint]
  );

  const initializeGraph = useCallback(
    (cloudFlowNodes: CloudFlowNode[], firstNodeId: string | null) => {
      if (!firstNodeId) {
        return;
      }

      const nodesWithGhosts = mapLeafNodesWithGhosts(cloudFlowNodes, firstNodeId);
      const flowNodes = mapCloudFlowNodes(nodesWithGhosts);
      const flowEdges = mapTransitionsToEdges(nodesWithGhosts);
      const sortedEdges = sortEdgesByHandle(flowEdges || []);

      const localStateNodes = getNodes();

      const uiEnrichedNodes = mergeCloudflowNodes(
        flowNodes,
        localStateNodes,
        handleAddNode,
        handleEditNode,
        handleDeleteNode
      );

      applyLayoutAndSetState(uiEnrichedNodes, sortedEdges);
    },
    [getNodes, handleAddNode, handleEditNode, handleDeleteNode, applyLayoutAndSetState]
  );

  useEffect(() => {
    const signature = cloudFlowNodes
      .filter((node) => node.type !== CloudFlowNodeType.GHOST)
      .map((node) => `${node.id}|${Boolean(node.approval?.required)}`)
      .join(",");

    if (prevNodeSignature.current !== signature) {
      initializeGraph(cloudFlowNodes, cloudFlow?.firstNode);
    }

    prevNodeSignature.current = signature;
  }, [cloudFlow?.firstNode, cloudFlowNodes, initializeGraph]);

  const handleDeleteActions = useCallback(
    async (manageIfActionsId: string) => {
      const currentNode = findNodeById(getNodes(), manageIfActionsId);
      if (!currentNode) {
        return;
      }
      const { nodes: outnodes } = getTreeOfOutgoers(getNodes(), getEdges(), manageIfActionsId);
      const incomer = getIncomerNode(currentNode, getNodes(), getEdges());
      const nodesToReplace = utils.handleDeleteActions(flowId, currentNode, incomer, outnodes);
      const { deletedNodes, addedNode } = nodesToReplace;

      try {
        const nodeToDelete = currentNode.id;
        const shouldCascadeDelete = deletedNodes.length > 1;
        const replaceNodesInput = {
          deleted: { nodeId: nodeToDelete, shouldCascadeDelete },
          created: { data: addedNode },
        };
        const response = await replaceNodes(customerId, flowId, replaceNodesInput);

        if (response?.deleted && response?.created) {
          deleteCloudFlowNode({
            deletedNodes: response.deleted.deletedNodes,
            updatedNodes: extractTransitions(response.deleted.updatedNodes),
          });

          addCloudFlowNode({
            addedNode: response.created.addedNode,
            updatedNodes: extractTransitions(response.created.updatedNodes),
          });
        }
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [getNodes, getEdges, flowId, replaceNodes, customerId, deleteCloudFlowNode, addCloudFlowNode]
  );

  const handleMoveActions = useCallback(
    async (manageIfActionsId: string, moveToTrue: boolean) => {
      const currenTargetNode = findNodeById(getNodes(), manageIfActionsId);
      if (!currenTargetNode) {
        return;
      }
      try {
        const incomer = getIncomerNode(currenTargetNode, getNodes(), getEdges());
        const updateNodesPayload = utils.handleMoveActions(flowId, currenTargetNode, moveToTrue, incomer);
        const nodesResponse = await updateNodes(customerId, updateNodesPayload);
        if (nodesResponse) {
          upsertCloudFlowNodes(nodesResponse);
        }
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [customerId, flowId, getEdges, getNodes, updateNodes, upsertCloudFlowNodes]
  );

  const onSaveManageIfActionsDialog = useCallback(
    async (choice: string) => {
      const currentNode = findNodeById(getNodes(), manageIfActionsId);

      if (!currentNode) {
        return;
      }

      const { type: nodeType, id: nodeId, position } = currentNode;

      if (!nodeType || !nodeId || !position) {
        return;
      }

      switch (choice) {
        case CHOICE_OPTIONS.MOVE_ACTIONS_TO_TRUE:
          await handleMoveActions(manageIfActionsId, true);
          break;
        case CHOICE_OPTIONS.MOVE_ACTIONS_TO_FALSE:
          await handleMoveActions(manageIfActionsId, false);
          break;
        case CHOICE_OPTIONS.DELETE_ACTIONS:
          await handleDeleteActions(manageIfActionsId);
          break;
      }

      setManageIfActionsId("");
    },
    [getNodes, handleDeleteActions, handleMoveActions, manageIfActionsId]
  );

  const onEdgeClick: EdgeMouseHandler<Edge> = useCallback(
    (_event, edgeData) => {
      const sourceNode = findNodeById(getNodes(), edgeData.source);
      const targetNode = findNodeById(getNodes(), edgeData.target);
      if (sourceNode && targetNode) {
        handleEdgeClick(edgeData);
      }
    },
    [getNodes, handleEdgeClick]
  );
  const handleNodeOperation = useCallback(
    async (nodeId: string, item: BaseCloudflowHit, operation: NodeOperationType) => {
      const currentNode = findNodeById(getNodes(), nodeId);
      if (!currentNode) {
        return;
      }

      const parentNode = getIncomerNode(currentNode, getNodes(), getEdges());
      const targetNode = getOutgoers(currentNode, getNodes(), getEdges())[0];

      const incomingEdgeLabel =
        parentNode.type === CloudFlowNodeType.CONDITION ? getLabelForTargetNode(nodeId) : undefined;

      closeAllModals();
      setSelectedProvider(undefined);

      try {
        switch (operation) {
          case NodeOperationType.CREATE:
            {
              const newNodeRequestData = getCreateActionNodePayload(item, parentNode, incomingEdgeLabel, currentNode);
              const nodesResponse = await createOrUpdate(customerId, flowId, newNodeRequestData);
              if (nodesResponse) {
                addCloudFlowNode({
                  addedNode: nodesResponse?.addedNode,
                  updatedNodes: extractTransitions(nodesResponse.updatedNodes),
                });
              }
            }
            break;
          case NodeOperationType.UPDATE: {
            const newNodeRequestData = getUpdateActionNodePayload(
              nodeId,
              currentNode.data.nodeData.transitions,
              item,
              targetNode
            );
            const updatedNodes = getNodes().map((node) => {
              if (node.id === nodeId) {
                return {
                  ...node,
                  data: {
                    ...node.data,
                    nodeData: {
                      ...node.data.nodeData,
                      ...newNodeRequestData.node,
                    },
                  },
                };
              }
              return node;
            });

            applyLayoutAndSetState(updatedNodes, getEdges());

            await createOrUpdate(customerId, flowId, newNodeRequestData);
            break;
          }
        }
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [
      getNodes,
      getEdges,
      getLabelForTargetNode,
      closeAllModals,
      createOrUpdate,
      customerId,
      flowId,
      addCloudFlowNode,
      applyLayoutAndSetState,
    ]
  );

  const onConfirmChangeTrigger = useCallback(
    async (choice: CHANGE_TRIGGER_OPTIONS) => {
      const targetCloudFlowNodeType: CloudFlowNodeType.TRIGGER | CloudFlowNodeType.MANUAL_TRIGGER | undefined =
        getTriggerByOption(choice);

      if (!targetCloudFlowNodeType || !activeNode) {
        return;
      }

      const { triggerNodeWithTransitions, updateNodesPayload } = getTriggerNodePayload(
        activeNode,
        targetCloudFlowNodeType,
        flowId
      );

      const updatedNode: Node<RFNode> = {
        ...triggerNodeWithTransitions,
        data: {
          ...triggerNodeWithTransitions.data,
          touched: true,
        },
      };

      const updatedNodes: Node<RFNode>[] = getNodes().map((node: Node<RFNode>) =>
        node.id === activeNode.id ? updatedNode : node
      );

      applyLayoutAndSetState(updatedNodes, getEdges());
      closeModal("trigger");

      const nodesResponse = await updateNodes(customerId, updateNodesPayload);
      if (nodesResponse) {
        upsertCloudFlowNodes(nodesResponse);
      }
    },
    [
      activeNode,
      flowId,
      getNodes,
      applyLayoutAndSetState,
      getEdges,
      closeModal,
      updateNodes,
      customerId,
      upsertCloudFlowNodes,
    ]
  );

  const onConnect: OnConnect = useCallback(
    (params: Connection | Edge) => {
      setEdges((eds) => addEdge(params, eds));
    },
    [setEdges]
  );

  const onChangeActiveNode = useCallback(
    (nodeType: CloudFlowNodeType, nodeId: string) => {
      selectNode(null);
      // setFocusedNodeId(nodeId); TODO: check id needed?
      if (nodeType === CloudFlowNodeType.ACTION) {
        setOperationType(NodeOperationType.UPDATE);
        openModal("action", nodeId);
      }
    },
    [openModal, selectNode]
  );

  useEffect(() => {
    if (isModalVisible("action") && !focusedNodeId) {
      onChangeActiveNode(CloudFlowNodeType.ACTION, activeNode?.id);
    }
  }, [activeNode?.id, focusedNodeId, isModalVisible, onChangeActiveNode]);

  const saveCloudFlow = useCallback(
    async (customerId: string, nodes: UpdateCloudflowNodes) => {
      try {
        const response = await updateNodes(customerId, nodes);
        if (response) {
          upsertCloudFlowNodes(response);
        }
        setNodes((prevNodes) =>
          prevNodes.map((node) => ({
            ...node,
            data: {
              ...node.data,
              touched: false,
            },
          }))
        );
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [updateNodes, setNodes, upsertCloudFlowNodes]
  );

  const publishCloudFlow = useCallback(
    async (customerId: string, flowId: string, nodes: UpdateCloudflowNodes | undefined) => {
      try {
        const response = await publishCloudflow(customerId, flowId, nodes);
        if (response) {
          const updatedResponse = { updatedNodes: response?.updatedNodes, addedNodes: response?.addedNodes };
          upsertCloudFlowNodes(updatedResponse);

          setNodes((prevNodes) =>
            prevNodes.map((node) => ({
              ...node,
              data: {
                ...node.data,
                touched: false,
              },
            }))
          );
        }
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [publishCloudflow, setNodes, upsertCloudFlowNodes]
  );

  const data = useMemo(
    () => ({
      nodes,
      edges,
      getEdges,
      getNodes,
      focusedNodeId,
      handleEditNode,
      onConnect,
      onEdgeClick,
      onConfirmDeleteIfNode,
      deleteIfNodeId,
      setDeleteIfNodeId,
      manageIfActionsId,
      setManageIfActionsId,
      onSaveManageIfActionsDialog,
      handleAddNode,
      onChangeActiveNode,
      selectNode,
      setNodes,
      saveCloudFlow,
      publishCloudFlow,
      interactionEnabled,
      onConfirmChangeTrigger,
      operationType,
      activeNode,
      handleNodeOperation,
      onNodesChange,
      onEdgesChange,
      handleAddBlueprint,
      nodesWithReferencedNode,
      handleDeleteReferencedNode,
      selectedProvider,
    }),
    [
      nodes,
      edges,
      getEdges,
      getNodes,
      focusedNodeId,
      onNodesChange,
      onEdgesChange,
      handleEditNode,
      onConnect,
      onEdgeClick,
      onConfirmDeleteIfNode,
      deleteIfNodeId,
      manageIfActionsId,
      onSaveManageIfActionsDialog,
      handleAddNode,
      onChangeActiveNode,
      selectNode,
      setNodes,
      saveCloudFlow,
      publishCloudFlow,
      interactionEnabled,
      onConfirmChangeTrigger,
      operationType,
      activeNode,
      handleNodeOperation,
      handleAddBlueprint,
      nodesWithReferencedNode,
      handleDeleteReferencedNode,
      selectedProvider,
    ]
  );

  return <NodeEdgeManagerContext.Provider value={data}>{children}</NodeEdgeManagerContext.Provider>;
};
