import { useId } from "react";

import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useFormikContext } from "formik";

import { cloudflowTexts } from "../../../../../../assets/texts";
import { useFieldCommonProps } from "../../../ApiActionParametersForm/useFieldCommonProps";
import { FieldSectionHeader } from "../../../Common/FieldSectionHeader";
import { FormatDateForm } from "./FormatDateForm";
import { ModifyDateForm } from "./ModifyDateForm";
import { type DateTimeSchema } from "./schema";

export const ActionPicker = () => {
  const labelId = useId();
  const { getFieldProps } = useFormikContext<DateTimeSchema>();
  const typeField = getFieldProps<DateTimeSchema["type"]>("type");
  const actionCommonProps = useFieldCommonProps(typeField, "Transform action", true);

  return (
    <Stack
      spacing={2}
      sx={{
        mb: 2,
      }}
    >
      <FieldSectionHeader
        title={cloudflowTexts.DEFINE_TRANSFORM_ACTION}
        subtitle={cloudflowTexts.ADD_TRANSFORM_ACTION}
      />
      <FormControl fullWidth required error={actionCommonProps.error}>
        <InputLabel size="small" id={labelId}>
          {actionCommonProps.label}
        </InputLabel>
        <Select
          labelId={labelId}
          size="small"
          {...actionCommonProps}
          onChange={typeField.onChange}
          sx={{ textTransform: "capitalize" }}
        >
          {["add", "subtract", "format"].map((type) => (
            <MenuItem key={type} value={type} sx={{ textTransform: "capitalize" }}>
              {type}
            </MenuItem>
          ))}
        </Select>
        {actionCommonProps.error && <FormHelperText>{actionCommonProps.helperText}</FormHelperText>}
      </FormControl>
      {(typeField.value === "add" || typeField.value === "subtract") && <ModifyDateForm modifier={typeField.value} />}
      {typeField.value === "format" && <FormatDateForm />}
    </Stack>
  );
};
