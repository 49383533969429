import { useEffect, useMemo, useState } from "react";

import {
  type ApiServiceBaseOperationDescriptor,
  CloudFlowNodeType,
  CloudFlowProvider,
  ModelType,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import { type WithFirebaseModel } from "@doitintl/models-firestore";
import { type FormikErrors } from "formik";
import * as yup from "yup";

import { type ConfigurationTab, type ErrorKeys, type NodeConfigs } from "../../../types";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

export const useTabs = (nodeConfig: NodeConfigs) =>
  useMemo(() => {
    const tabs: ConfigurationTab[] = [];

    switch (nodeConfig.type) {
      case CloudFlowNodeType.ACTION: {
        const parameters = nodeConfig.parameters as NodeConfigs<CloudFlowNodeType.ACTION>["parameters"];

        if (parameters) tabs.push("Parameters");
        if (parameters.provider === CloudFlowProvider.DoiT) {
          tabs.push("Test");
        } else {
          tabs.push("Permissions", "Test");
        }

        break;
      }
      case CloudFlowNodeType.TRIGGER:
        tabs.push("Schedule");
        break;
      case CloudFlowNodeType.FILTER:
        tabs.push("FilterParameters");
        break;
      case CloudFlowNodeType.TRANSFORMATION:
        tabs.push("TransformParameters");
        break;
      case CloudFlowNodeType.MANUAL_TRIGGER:
        tabs.push("ManualTrigger");
        break;
      default:
        if (nodeConfig.parameters) tabs.push("Parameters");
        break;
    }

    return tabs;
  }, [nodeConfig.type, nodeConfig.parameters]);

export const useFormValidation = ({
  operation,
  model,
}: {
  operation: WithFirebaseModel<ApiServiceBaseOperationDescriptor> | null;
  model: WithFirebaseModel<UnwrappedApiServiceModelDescriptor> | null;
}) => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const [configurationFormValid, setConfigurationFormValid] = useState<boolean>();
  const [inputModelValid, setInputModelValid] = useState<boolean>();

  useEffect(() => {
    setConfigurationFormValid(undefined);
    setInputModelValid(undefined);
  }, [nodeConfig.id]);

  useEffect(() => {
    if (!operation) return;
    if (!operation?.parameters) {
      setConfigurationFormValid(true);
    }
  }, [operation, nodeConfig.parameters.operation.provider]);

  useEffect(() => {
    if (model?.type === ModelType.STRUCTURE && !model.requiredMembers?.length) {
      setInputModelValid(true);
    }
  }, [model]);

  return { configurationFormValid, setConfigurationFormValid, inputModelValid, setInputModelValid };
};

export function useErrorUpdates<T extends CloudFlowNodeType>({
  isValid,
  errorKey,
  errorMessage,
  isFormLoading = false,
}: {
  isValid: boolean;
  errorKey: ErrorKeys;
  errorMessage: string;
  isFormLoading?: boolean;
}) {
  const { nodeConfig, updateNode } = useNodeConfigurationContext<T>();

  useEffect(() => {
    if (isFormLoading) {
      return;
    }

    if (isValid === !nodeConfig.errors[errorKey]) return;

    updateNode((prevNode) => {
      const errors = { ...prevNode.errors };
      if (isValid) {
        delete errors[errorKey];
      } else {
        errors[errorKey] = errorMessage;
      }

      return { errors };
    });
  }, [isValid, errorKey, errorMessage, updateNode, nodeConfig.errors, isFormLoading]);
}

export const useInitialErrors = <T extends yup.Schema>(validationSchema: T, initialValues: yup.InferType<T>) =>
  useMemo(() => {
    try {
      validationSchema.validateSync(initialValues, { abortEarly: false });
      return undefined;
    } catch (err) {
      if (err instanceof yup.ValidationError) {
        return err.inner.reduce((acc, error) => {
          if (error.path) {
            acc[error.path] = error.message;
          }
          return acc;
        }, {} as FormikErrors<unknown>);
      }
    }
  }, [validationSchema, initialValues]);
