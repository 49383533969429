import { useId } from "react";

import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { DATE_TIME_FORMATS } from "@services/cmp/models/src";
import { useFormikContext } from "formik";

import { useFieldCommonProps } from "../../../ApiActionParametersForm/useFieldCommonProps";
import { FieldSectionHeader } from "../../../Common/FieldSectionHeader";
import { type DateTimeSchema } from "./schema";

const groupedFormats = DATE_TIME_FORMATS.reduce(
  (acc, format) => {
    if (format.includes("HH")) {
      acc.withTime.push(format);
    } else {
      acc.withoutTime.push(format);
    }
    return acc;
  },
  { withTime: [] as string[], withoutTime: [] as string[] }
);

export const FormatDateForm = () => {
  const { getFieldProps } = useFormikContext<DateTimeSchema>();

  const formatLabelId = useId();
  const formatCommonProps = useFieldCommonProps(getFieldProps("format"), "New date format", true);

  const newFieldNameCommonProps = useFieldCommonProps<string>(getFieldProps("newFieldName"), "New field name", true);

  return (
    <Stack
      spacing={2}
      sx={{
        pb: 4,
      }}
    >
      <TextField fullWidth variant="outlined" size="small" {...newFieldNameCommonProps} />
      <FieldSectionHeader title="How to use change format" subtitle="Define the new format of the date" />
      <FormControl fullWidth required error={formatCommonProps.error}>
        <InputLabel size="small" id={formatLabelId}>
          {formatCommonProps.label}
        </InputLabel>
        <Select labelId={formatLabelId} size="small" {...formatCommonProps}>
          <ListSubheader>Date only</ListSubheader>
          {groupedFormats.withoutTime.map((type) => (
            <MenuItem key={type} value={type} sx={{ marginLeft: 1 }}>
              {type}
            </MenuItem>
          ))}
          <ListSubheader>Date and time</ListSubheader>
          {groupedFormats.withTime.map((type) => (
            <MenuItem key={type} value={type} sx={{ marginLeft: 1 }}>
              {type}
            </MenuItem>
          ))}
        </Select>
        {formatCommonProps.error && <FormHelperText>{formatCommonProps.helperText}</FormHelperText>}
      </FormControl>
    </Stack>
  );
};
