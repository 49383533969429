import { useCallback, useEffect, useId, useState } from "react";

import { type ReferencedNodeValue } from "@doitintl/cmp-models";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useFormikContext } from "formik";

import { cloudflowTexts } from "../../../../../../assets/texts";
import { FieldChip } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldChip";
import { ReferencedFieldStandalone } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldStandalone";
import { type NodeWitOutputModel } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { useFieldCommonProps } from "../../../ApiActionParametersForm/useFieldCommonProps";
import { FieldSectionHeader } from "../../../Common/FieldSectionHeader";
import { type DateTimeSchema } from "./schema";

export const NodeDataSourceForm = ({ referenceableNodes }: { referenceableNodes: NodeWitOutputModel[] }) => {
  const { getFieldProps, setValues } = useFormikContext<DateTimeSchema>();
  const referencedNodeIdProps = getFieldProps("referencedNodeField.referencedNodeId");

  const [selectedNode, setSelectedNode] = useState<NodeWitOutputModel[]>(
    referenceableNodes.filter(({ id }) => referencedNodeIdProps.value === id)
  );
  const [rootReferencedNodeValue, setRootReferencedNodeValue] = useState<ReferencedNodeValue>({
    referencedField: [],
    referencedNodeId: referencedNodeIdProps.value,
  });

  const referencedNodeLabelId = useId();
  const referencedNodeIdCommonProps = useFieldCommonProps(referencedNodeIdProps, cloudflowTexts.DATA_SOURCES, true);

  const referencedNodeFieldCommonProps = useFieldCommonProps(
    getFieldProps("referencedNodeField"),
    cloudflowTexts.REFERENCED_FIELD,
    true
  );

  useEffect(() => {
    setSelectedNode(referenceableNodes.filter(({ id }) => referencedNodeIdProps.value === id));
  }, [referenceableNodes, referencedNodeIdProps.value]);

  useEffect(() => {
    setRootReferencedNodeValue({
      referencedField: [],
      referencedNodeId: referencedNodeIdProps.value,
    });
  }, [referencedNodeIdProps.value]);

  const onReferencedNodeIdChange = useCallback(
    ({ target: { value } }) =>
      setValues((values) => ({
        ...values,
        referencedNodeField: {
          referencedNodeId: value,
          referencedField: [],
        },
      })),
    [setValues]
  );

  const isReferencedFieldDisabled = selectedNode.length === 0;

  return (
    <Stack
      spacing={2}
      sx={{
        mb: 3,
      }}
    >
      <FieldSectionHeader title={cloudflowTexts.SELECT_DATA_SOURCE} subtitle={cloudflowTexts.PICK_STEP_TO_TRANSFORM} />
      <FormControl fullWidth sx={{ mb: 3 }} required error={referencedNodeIdCommonProps.error}>
        <InputLabel size="small" id={referencedNodeLabelId}>
          {referencedNodeIdCommonProps.label}
        </InputLabel>
        <Select
          labelId={referencedNodeLabelId}
          size="small"
          disabled={referenceableNodes.length === 0}
          renderValue={() => <FieldChip tokens={selectedNode.map(({ name }) => name)} />}
          {...referencedNodeIdCommonProps}
          onChange={onReferencedNodeIdChange}
        >
          {referenceableNodes.map((node) => (
            <MenuItem key={node.id} value={node.id}>
              {node.name}
            </MenuItem>
          ))}
        </Select>
        {referencedNodeIdCommonProps.error && <FormHelperText>{referencedNodeIdCommonProps.helperText}</FormHelperText>}
      </FormControl>
      <FieldSectionHeader
        title={cloudflowTexts.SELECT_FIELD_TO_TRANSFORM}
        subtitle={cloudflowTexts.FROM_CHOSEN_STEP_TO_TRANSFORM}
      />
      <ReferencedFieldStandalone
        {...referencedNodeFieldCommonProps}
        disabled={isReferencedFieldDisabled}
        referenceableNodes={selectedNode}
        rootReferencedNodeValue={rootReferencedNodeValue}
        tooltip={isReferencedFieldDisabled ? cloudflowTexts.SELECT_VALUE_FROM_DATA_SOURCE : ""}
      />
    </Stack>
  );
};

export default NodeDataSourceForm;
