import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { Card, Stack } from "@mui/material";

import { cloudflowTexts } from "../../../../../assets/texts";
import { DateTimeTransformForm } from "./DateTimeTransform/DateTimeTransformForm";
import { NodeCardHeader } from "./NodeCardHeader";

const DateTimeTransformParametersTab = () => (
  <Stack
    sx={{
      px: 2,
      justifyContent: "center",
      gap: 2,
      mt: 1,
    }}
  >
    <Card>
      <NodeCardHeader
        avatar={<ManageHistoryIcon color="primary" fontSize="small" />}
        subheader={cloudflowTexts.DATETIME_TRANSFORM_DESCRIPTION}
        title={cloudflowTexts.DATETIME_TRANSFORM}
      />
    </Card>
    <DateTimeTransformForm />
  </Stack>
);

export default DateTimeTransformParametersTab;
