import { useCallback, useState } from "react";

import { type CloudFlowNodeType, type DateTimeTransformations } from "@doitintl/cmp-models";
import { Form, Formik } from "formik";
import noop from "lodash/noop";

import { FormChangesListener } from "../../../Common/FormChangesListener";
import { useReferenceableNodes } from "../../../Common/hooks/useReferenceableNodes";
import { useNodeConfigurationContext } from "../../NodeConfigurationContext";
import { useErrorUpdates, useInitialErrors } from "../hooks";
import { ActionPicker } from "./ActionPicker";
import NodeDataSourceForm from "./NodeSourceForm";
import { type DateTimeSchema, generateDateTimeTransformSchema } from "./schema";

export const DateTimeTransformForm = () => {
  const { nodeConfig, updateNode } = useNodeConfigurationContext<CloudFlowNodeType.DATETIME_TRANSFORM>();
  const [referenceableNodes, loading] = useReferenceableNodes(nodeConfig.id);
  const schema = generateDateTimeTransformSchema(referenceableNodes);
  const [isValid, setIsValid] = useState(nodeConfig.status !== "error");

  const initialValues: DateTimeSchema = {
    ...schema.getDefault(),
    ...nodeConfig.parameters.transformation,
    referencedNodeField: {
      referencedField: nodeConfig.parameters.referencedField ?? [],
      referencedNodeId: nodeConfig.parameters.referencedNodeId ?? "",
    },
  };

  const initialErrors = useInitialErrors(schema, initialValues);

  useErrorUpdates<CloudFlowNodeType.DATETIME_TRANSFORM>({
    isValid,
    errorKey: "param_error",
    errorMessage: "Invalid params",
    isFormLoading: loading,
  });

  const onValuesChange = useCallback(
    (values: DateTimeSchema) => {
      const buildTransformation = (values: DateTimeSchema): DateTimeTransformations => {
        switch (values.type) {
          case "add":
            return { type: "add", newFieldName: values.newFieldName, duration: values.duration, value: values.value };
          case "subtract":
            return {
              type: "subtract",
              newFieldName: values.newFieldName,
              duration: values.duration,
              value: values.value,
            };
          case "format":
            return { type: "format", newFieldName: values.newFieldName, format: values.format };
        }
      };

      updateNode((prevNodeConfig) => {
        const currentReferencedNode = referenceableNodes.find(
          (n) => n.id === values.referencedNodeField?.referencedNodeId
        );
        const previousReferencedNode = referenceableNodes.find(
          (n) => n.id === prevNodeConfig.parameters?.referencedNodeId
        );

        const hasUpdatedName =
          prevNodeConfig.name !== `Date/time transform on ${previousReferencedNode?.name}` &&
          prevNodeConfig.name !== "Date/time transform";

        return {
          ...(!hasUpdatedName &&
            currentReferencedNode && { name: `Date/time transform on ${currentReferencedNode.name}` }),
          parameters: {
            referencedField: values.referencedNodeField?.referencedField ?? [],
            referencedNodeId: values.referencedNodeField?.referencedNodeId ?? "",
            transformation: buildTransformation(values),
          },
        };
      });
    },
    [referenceableNodes, updateNode]
  );

  if (loading) return null;

  return (
    <Formik
      validateOnChange
      validateOnBlur
      validateOnMount
      initialValues={initialValues}
      validationSchema={schema}
      enableReinitialize
      initialErrors={initialErrors}
      onSubmit={noop}
    >
      <Form>
        <NodeDataSourceForm referenceableNodes={referenceableNodes} />
        <ActionPicker />
        <FormChangesListener onValidityChange={setIsValid} onValuesChange={onValuesChange} />
      </Form>
    </Formik>
  );
};
